import "./App.css";
import AppRoutes from "./routes/routes";
import { ThemeProvider, createTheme } from "@mui/material";

function App() {
	const theme = createTheme({
		palette: {
			primary: {
				main: "#4B006E",
			},
			secondary: {
				main: "#cfb53b",
			},
			mode: "dark",
			text: {
				primary: "#cfb53b",
			},
		},
		components: {
			MuiDivider: {
				styleOverrides: {
					root: {
						padding: 2,
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<AppRoutes />
			</div>
		</ThemeProvider>
	);
}

export default App;
