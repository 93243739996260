import { Divider, Stack } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";
import "./video-page.css";

const VideoPage = () => {
	return (
		<div>
			<h1 className="videos-header">VIDEOS</h1>
			<Stack
				direction={"column"}
				spacing={4}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Stack
					direction={"row"}
					spacing={3}
					sx={{
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/Mff7cKmsJcQ?si=tLwfZanjaUHFPrYh"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
						className="yt-iframe"
					></iframe>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/_WtoZvK1kB4?si=9KnUCaVuB-incG_O"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
						className="yt-iframe"
					></iframe>
				</Stack>
				<Stack
					direction={"row"}
					spacing={3}
					sx={{
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/Qkxn1wjulNY?si=dvUuU1guiMAHVkDs"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
						className="yt-iframe"
					></iframe>

					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/MmeiO0pW9hI?si=-qz1wmwhx85aAl0Q"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
						className="yt-iframe"
					></iframe>
				</Stack>
				<Stack
					direction={"row"}
					spacing={3}
					sx={{
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/F93i3kNa4x8?si=ce8-uEBDbjGRiD7P"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
						className="yt-iframe"
					></iframe>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/zj_8lHSEbGY?si=zXwj3064p2cnsMCS"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin"
						allowfullscreen
						className="yt-iframe"
					></iframe>
				</Stack>
			</Stack>

			<Divider>
				<h2>Behind the Scenes</h2>
			</Divider>
			<Stack spacing={2} direction={"row"} paddingBottom={10}>
				<ReactPlayer
					url="https://ldg-productions.s3.us-east-2.amazonaws.com/IMG_5829.mov"
					controls={true}
					width="800px"
					height="450px"
				/>
				<ReactPlayer
					url="https://ldg-productions.s3.us-east-2.amazonaws.com/IMG_6085.mov"
					controls={true}
					width="800px"
					height="450px"
				/>
			</Stack>
		</div>
	);
};

export default VideoPage;
