import { Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./footer.css";

const Footer = () => {
	return (
		<div style={{ textAlign: "center" }}>
			<Toolbar sx={{ justifyContent: "center" }}>
				<Stack direction="column" spacing={2}>
					<Typography color="white">
						LaShawn D. Gary Productions, a division of L.N.C. MUSIC INC.
					</Typography>
					<Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
						<a
							href="https://www.facebook.com/profile.php?id=100062870355329"
							target="_blank"
						>
							<FacebookIcon fontSize="large" color="white"></FacebookIcon>
						</a>
						<a
							href="https://www.instagram.com/lashawndgary?igsh=MTY5NmF3bjdudmhr"
							target="_blank"
						>
							<InstagramIcon fontSize="large"></InstagramIcon>
						</a>
						<a
							href="https://www.youtube.com/channel/UC3IwjNgTYe9JxiP5fXIbMoQ"
							target="_blank"
						>
							<YouTubeIcon fontSize="large"></YouTubeIcon>
						</a>
					</Stack>
				</Stack>
			</Toolbar>
		</div>
	);
};

export default Footer;
