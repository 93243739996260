import {
	Box,
	Button,
	FormControl,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./contact.css";
import { post } from "aws-amplify/api";

const Contact = () => {
	const [contactName, setContactName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [message, setMessage] = useState("");
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [showForm, setShowForm] = useState(true);
	const [showError, setShowErrorMessage] = useState(false);
	const errorMessage =
		"An error occurred when attempting to send your contact information. Please ensure all required fields are entered correctly and try again. If the issue continues, our team will review and will attempt to get your message sent as soon as possible.";

	function validateFields() {
		if (contactName.length === 0 || contactName.length > 70) {
			setButtonDisabled(true);
			return;
		}
		if (phoneNumber.length > 11 && isNaN(phoneNumber)) {
			setButtonDisabled(true);
			return;
		}
		if (emailAddress.length === 0) {
			setButtonDisabled(true);
			return;
		}
		if (message.length === 0) {
			setButtonDisabled(true);
			return;
		}
		setButtonDisabled(false);
	}

	async function sendContactInformation() {
		try {
			const restOperation = post({
				apiName: "ldgApi",
				path: "/sendContact",
				options: {
					headers: {
						"Content-Type": "application/json",
					},
					body: {
						name: contactName,
						phoneNumber: phoneNumber,
						emailAddress: emailAddress,
						message: message,
					},
				},
			});

			const { body } = await restOperation.response;
			const response = await body.json();

			setShowErrorMessage(false);
			setShowForm(false);
		} catch (e) {
			setShowErrorMessage(true);
		}
	}

	return showForm ? (
		<div className="main">
			<h1>Contact</h1>
			<Stack
				direction="column"
				spacing={4}
				sx={{ marginRight: "auto", marginLeft: "auto", width: 500 }}
			>
				<FormControl>
					<TextField
						required
						id="name"
						label="Name"
						onChange={(e) => {
							setContactName(e.target.value);
							validateFields();
						}}
						inputProps={{ maxLength: 70 }}
					/>
				</FormControl>
				<FormControl>
					<TextField
						id="phone-number"
						label="Phone Number"
						type="tel"
						InputProps={{ maxLength: 11 }}
						onChange={(e) => {
							setPhoneNumber(e);
							validateFields();
						}}
					></TextField>
				</FormControl>
				<FormControl>
					<TextField
						required
						id="email"
						label="Email Address"
						onChange={(e) => {
							setEmailAddress(e.target.value);
							validateFields();
						}}
					/>
				</FormControl>
				<FormControl>
					<TextField
						required
						id="message"
						label="Message"
						onChange={(e) => {
							setMessage(e.target.value);
							validateFields();
						}}
						inputProps={{ maxLength: 255 }}
					/>
				</FormControl>

				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						marginRight: "auto",
						marginLeft: "auto",
						paddingBottom: 20,
					}}
				>
					<Button
						variant="contained"
						onClick={() => sendContactInformation()}
						disabled={buttonDisabled}
						size="medium"
						sx={{
							width: "300px",
						}}
					>
						Send
					</Button>
				</div>
				{showError && (
					<Typography sx={{ color: "red" }}>{errorMessage}</Typography>
				)}
			</Stack>
		</div>
	) : (
		<div>
			<Box
				sx={{
					backgroundColor: "green",
					marginRight: "auto",
					marginLeft: "auto",
					width: 500,
				}}
			>
				<h2 style={{ color: "white" }}>Message Sent!</h2>
				<Typography sx={{ color: "white" }}>
					Thanks for reaching out. We will respond soon!
				</Typography>
			</Box>
		</div>
	);
};

export default Contact;
