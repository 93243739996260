import { useEffect, useState } from "react";
import links from "../../assets/links/new-music.txt";
import mainImage from "../../assets/images/main-image.jpg";
import youtubeLinks from "../../assets/links/youtube.txt";
import Videos from "../videos";
import "./home.css";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Snackbar,
	SnackbarContent,
	Stack,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import eylfNumber1 from "../../assets/images/everything-you're-looking-for-number-1.png";
import ReactPlayer from "react-player";
import { DataStore } from "aws-amplify/datastore";
import { Event } from "../../models";
import eylfBPRadioNumber1 from "../../assets/images/eylf-black-panet-radio-number-1.jpg";

const Home = () => {
	const [newMusicLink, setNewMusicLink] = useState("");
	const [youtubeVideos, setYoutubeVideos] = useState(null);
	const [snackbarOpen, setSnackbarState] = useState(true);
	const [upcomingEvent, setEvent] = useState();
	const [dataLoaded, setDataLoaded] = useState(false);

	function handleCloseSnackbar() {
		setSnackbarState(false);
	}

	useEffect(() => {
		getNewMusicLink();
		(async () => {
			const youtubeLinks = await getYouTubeLinks();
			const dataToSet = [];
			for (let link of youtubeLinks) {
				const id = link.split("https://www.youtube.com/watch?v=")[1];
				const data = await fetchYoutubeData(id);

				dataToSet.push({
					thumbnailUrl: data.items[0].snippet.thumbnails.standard.url,
					url: link,
					id: id,
				});
			}
			setYoutubeVideos(dataToSet);
			const events = await DataStore.query(Event);
			const filteredEvents = events.filter(
				(event) => Date.parse(event.date) > Date.now()
			);
			filteredEvents.sort((a, b) => Date.parse(a.date) > Date.parse(b.date));

			if (filteredEvents.length === 0) {
				setEvent(null);
			} else {
				setEvent(filteredEvents[0]);
			}
		})();
		setDataLoaded(true);
	}, [dataLoaded]);
	async function fetchYoutubeData(id) {
		return await fetch(
			`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${id}&maxResults=1&key=AIzaSyAYuUthfDsmGd-7YNIVazIkMFmECIUR4Ew`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		).then((youtubeData) => {
			return youtubeData.json();
		});
	}
	function getNewMusicLink() {
		let link = "";
		fetch(links)
			.then((result) => {
				return result.text();
			})
			.then((r) => {
				setNewMusicLink(r);
			});
		return link;
	}

	const navigate = useNavigate();

	async function getYouTubeLinks() {
		return await fetch(youtubeLinks)
			.then((result) => {
				return result.text();
			})
			.then((r) => {
				const list = r.split("\n");
				return list;
			});
	}
	function getSnackBarEventMessage() {
		const options = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
			timeZone: "UTC",
		};
		const eventDetail = {
			title: upcomingEvent.title,
			date:
				new Date(upcomingEvent.date).toLocaleDateString(undefined, options) +
				" " +
				upcomingEvent?.startTime +
				(upcomingEvent?.endTime ? " - " + upcomingEvent?.endTime : ""),

			location: upcomingEvent.location,
		};
		return (
			<div>
				<h4>Upcoming Event</h4> <p>{eventDetail.title}</p>
				<p>{eventDetail.date}</p>
				<div>
					<Button onClick={() => navigate("/Events")} variant="contained">
						Event Details
					</Button>
				</div>
			</div>
		);
	}
	const eventDetailsButton = (
		<div className="dismiss-button">
			<Button onClick={() => handleCloseSnackbar()}>Dismiss</Button>
		</div>
	);

	return (
		dataLoaded && (
			<div>
				{upcomingEvent && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={snackbarOpen}
						autoHideDuration={6000}
					>
						<SnackbarContent
							action={eventDetailsButton}
							message={getSnackBarEventMessage()}
						></SnackbarContent>
					</Snackbar>
				)}

				<div className="header">
					<h1 className="header-h1">LaShawn D. Gary Productions</h1>
					<div>
						<a href={newMusicLink} target="_blank">
							<img src={mainImage}></img>
						</a>
					</div>
				</div>
				<h2 style={{ fontSize: "3em", color: "white" }}>LATEST</h2>
				<Stack
					justifyContent={"center"}
					alignItems={"center"}
					direction={"column"}
					spacing={3}
				>
					<Card sx={{ maxWidth: 450 }}>
						<CardMedia
							sx={{ height: 250 }}
							image={eylfBPRadioNumber1}
							title="Eveything You're Looking For #1 on Black Panet Radio"
						/>
						<CardContent>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
								style={{ color: "white" }}
							>
								Everything You're Looking For
							</Typography>
							<Typography variant="body2" color="text.secondary">
								June 13, 2024 - Everything You're Looking For reached #1 on The
								Black Panet Radio Italy
							</Typography>
						</CardContent>
						<CardActions>
							<a
								className="card-link"
								href="https://songwhip.com/lashawndgary/evolution"
								target="_blank"
							>
								<Button style={{ color: "white" }} size="medium">
									Stream Now
								</Button>
							</a>

							<a
								className="card-link"
								href="https://www.youtube.com/watch?v=Mff7cKmsJcQ"
								target="_blank"
							>
								<Button style={{ color: "white" }} size="medium">
									Watch on YouTube
								</Button>
							</a>
						</CardActions>
					</Card>
					<Card sx={{ maxWidth: 450 }}>
						<CardMedia
							sx={{ height: 250 }}
							image={eylfNumber1}
							title="Eveything You're Looking For #1"
						/>
						<CardContent>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
								style={{ color: "white" }}
							>
								Everything You're Looking For
							</Typography>
							<Typography variant="body2" color="text.secondary">
								May 19, 2024 - Everything You're Looking For reached #1 on The
								Official Indie R&B Chart
							</Typography>
						</CardContent>
						<CardActions>
							<a
								className="card-link"
								href="https://songwhip.com/lashawndgary/evolution"
								target="_blank"
							>
								<Button style={{ color: "white" }} size="medium">
									Stream Now
								</Button>
							</a>

							<a
								className="card-link"
								href="https://www.youtube.com/watch?v=Mff7cKmsJcQ"
								target="_blank"
							>
								<Button style={{ color: "white" }} size="medium">
									Watch on YouTube
								</Button>
							</a>
						</CardActions>
					</Card>
					<Card sx={{ maxWidth: 450 }} style={{ maxWidth: 450, height: 150 }}>
						<CardContent>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
								style={{ color: "white" }}
							>
								Soul Cafe Radio NYC
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Interview with Soul Cafe Radio NYC
							</Typography>
						</CardContent>
						<CardActions>
							<a
								className="card-link"
								href="https://on.soundcloud.com/Amj6korjD2U1Shxk9"
								target="_blank"
							>
								<Button style={{ color: "white" }} size="medium">
									Listen Now
								</Button>
							</a>
						</CardActions>
					</Card>
					<h3 style={{ color: "white" }}>Emmy Award Announcement</h3>
					<ReactPlayer
						url="https://ldg-productions.s3.us-east-2.amazonaws.com/IMG_5090.mov"
						controls={true}
						width="480px"
						height="270px"
						className="emmy-video"
					/>
				</Stack>

				{youtubeVideos && (
					<div className="videos-section" id="videos">
						<h1 style={{ fontSize: "3em", color: "white" }}>VIDEOS</h1>
						<Videos youtubeVideos={youtubeVideos} />
					</div>
				)}
			</div>
		)
	);
};

export default Home;
