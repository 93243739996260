import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Divider,
	Stack,
	Typography,
} from "@mui/material";
import React from "react";
import afm from "../../assets/images/afm.gif";
import ascap from "../../assets/images/ascap.gif";
import omegapsiphi from "../../assets/images/omegapsiphi.gif";
import phimualpha from "../../assets/images/phimualpha.gif";
import "./about-me.css";
import mfai from "../../assets/images/mfai.png";
import southfieldat from "../../assets/images/southfield-a-and-t.png";
import grandWithEmmy from "../../assets/images/grand-with-emmy.jpg";
import oppLine from "../../assets/images/opp-line.png";
import withMom from "../../assets/images/ldg-with-mom.png";
import ldgFocus from "../../assets/images/ldg-focus.png";
import oppConference from "../../assets/images/opp-conference.png";
import ldgUofL from "../../assets/images/ldg-uofl.png";
import daasChoir from "../../assets/images/daas-choir.png";
import ldgLive from "../../assets/images/ldg-live.png";
import ldg from "../../assets/images/main-original.png";

const AboutMe = () => {
	return (
		<div>
			<Typography
				variant="h2"
				textAlign="center"
				color="white"
				paddingTop={4}
				paddingBottom={2}
			>
				LaShawn D. Gary
			</Typography>
			<img
				src={ldgFocus}
				alt="image of LaShawn Gary"
				className="main-image"
			></img>
			<Box
				sx={{
					flexGrow: 1,
					display: { xs: "none", md: "flex" },
				}}
			>
				<Card
					className="main-card"
					sx={{
						display: "flex",
						overflow: "auto",
						background: "none",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column", width: "70%" }}>
						<CardContent sx={{ flex: "1 0 auto" }}>
							<Typography
								color="white"
								lineHeight={2}
								className="outfit-about-me"
							>
								DILIGENT PURSUIT best describes the systematic approach I have
								taken to the fulfillment of my dreams. It includes spirituality,
								education, creativity, performance, technology, preparation and
								perseverance. Music production and film score has been the
								intention of my preparation. A craftsman knows the versatility
								of his craft, which broadens his ability to capture the
								imaginations of others and accomplish the goal. The continuation
								of my journey has been grounded through my faith in God. The
								diligence of my pursuit has been in perseverance, recognizing
								that when one door closes, God opens another.
							</Typography>
							<Divider></Divider>
							<Typography
								color="white"
								lineHeight={2}
								className="outfit-about-me"
							>
								<strong>LaShawn D. Gary </strong>– Emmy Award Winning Producer,
								Composer, Smooth Jazz Artist and Pianist. He is a native of
								Detroit, Michigan and received his Bachelor of Science Degree in
								Instrumental Music Education from Southern University A&M
								College, and earned a Masters of Music Education Degree from the
								University of Louisville. LaShawn has released seven Smooth Jazz
								singles: “Now is the Time (2019)”, “Love, Peace & Praise
								(2020)”, “Inception, featuring Mark Whitfield (2021)”, “Line
								Brothers (2021)”, “On the Yard” (2022), “Transitions” (2022) and
								“For Your Love (feat. Willie Bradley)” (2023). His newest single
								“Everything You're Looking For (feat. Penny Wells)”, is from his
								recently released album, Evolution. LaShawn is also the featured
								artist and co-writer of Jeff Canady’s “Light Within.” He has
								also performed as a session musician on several smooth jazz and
								R&B projects. LaShawn has worked as a composer/arranger in
								commercial advertisements with Burrell Communications, Global
								Hue and Yessian Music. His musical talents were displayed on
								television in the production of the Toyota Avalon “Son-in-Law”
								commercial, the introductory advertisement of the Ford Fiesta,
								Xfinity “Unplug” Commercial and the Happy’s Pizza Jingle
								featuring the Four Tops. LaShawn was the Choir Director for the
								McDonald’s 365 Black Awards directing the Southern University
								Gospel Choir with Erica Campbell and the Louisiana Leadership
								Institute Drum-line performance with American Idol winner,
								singer, and actress Jordan Sparks. In March 2015, LaShawn worked
								with Grammy Award winning singer Patti Austin, Greg
								Phillinganes, and Sanchez Harley as the Assistant Choir Director
								for the Charles H. Wright Museum 50th Anniversary Celebration
								“Oh Freedom.” In 2015, LaShawn also released his debut album,
								“Love, Peace, and Praise.” He is married to Cherida D. Gary and
								together they have two children Nigel and Niyah.
							</Typography>
						</CardContent>
					</Box>
					<Stack
						direction="column"
						sx={{
							marginLeft: "auto",
							marginRight: "auto",
							marginBottom: "auto",
							marginTop: "auto",
						}}
						alignItems="center"
						justifyContent="center"
					>
						<CardMedia
							component="img"
							sx={{
								width: 576,
								height: 384,
							}}
							className="emmy-img"
							image={grandWithEmmy}
							alt="emmy picture"
						/>
						<CardContent>
							<Typography>Member of: </Typography>
							<Stack
								direction="row"
								spacing={1}
								justifyContent={"center"}
								alignItems={"center"}
							>
								<a href="http://www.sinfonia.org/" target="_blank">
									<img src={phimualpha} alt="phi mu alpha"></img>
								</a>
								<a href="https://oppf.org" target="_blank">
									<img src={omegapsiphi} alt="omega psi phi"></img>
								</a>
								<a href="http://www.ascap.com/" target="_blank">
									<img src={ascap} alt="ascap"></img>
								</a>
								<a href="http://www.afm.org/" target="_blank">
									<img src={afm} alt="sinfonia"></img>
								</a>
							</Stack>
							<a href="https://www.michiganfai.com/contact" target="_blank">
								<img
									src={mfai}
									alt="mfai"
									width={"280px"}
									height={"244px"}
								></img>
							</a>
						</CardContent>
					</Stack>
				</Card>
			</Box>

			<Box
				sx={{
					flexGrow: 1,
					display: { xs: "flex", md: "none" },
				}}
			>
				<Stack direction={"column"}>
					<Typography color="white" className="outfit-about-me">
						DILIGENT PURSUIT best describes the systematic approach I have taken
						to the fulfillment of my dreams. It includes spirituality,
						education, creativity, performance, technology, preparation and
						perseverance. Music production and film score has been the intention
						of my preparation. A craftsman knows the versatility of his craft,
						which broadens his ability to capture the imaginations of others and
						accomplish the goal. The continuation of my journey has been
						grounded through my faith in God. The diligence of my pursuit has
						been in perseverance, recognizing that when one door closes, God
						opens another.
					</Typography>
					<Divider></Divider>

					<div>
						<img
							className="emmy-img"
							src={grandWithEmmy}
							alt="emmy picture"
							style={{ float: "left" }}
						/>
						<Typography
							color="white"
							lineHeight={2}
							className="outfit-about-me"
						>
							LaShawn D. Gary – Emmy Award Winning Producer, Composer, Smooth
							Jazz Artist and Pianist. He is a native of Detroit, Michigan and
							received his Bachelor of Science Degree in Instrumental Music
							Education from Southern University A&M College, and earned a
							Masters of Music Education Degree from the University of
							Louisville. LaShawn has released seven Smooth Jazz singles: “Now
							is the Time (2019)”, “Love, Peace & Praise (2020)”, “Inception,
							featuring Mark Whitfield (2021)”, “Line Brothers (2021)”, “On the
							Yard” (2022), “Transitions” (2022) and “For Your Love (feat.
							Willie Bradley)” (2023). His newest single “Everything You're
							Looking For (feat. Penny Wells)”, is from his recently released
							album, Evolution. LaShawn is also the featured artist and
							co-writer of Jeff Canady’s “Light Within.” He has also performed
							as a session musician on several smooth jazz and R&B projects.
							LaShawn has worked as a composer/arranger in commercial
							advertisements with Burrell Communications, Global Hue and Yessian
							Music. His musical talents were displayed on television in the
							production of the Toyota Avalon “Son-in-Law” commercial, the
							introductory advertisement of the Ford Fiesta, Xfinity “Unplug”
							Commercial and the Happy’s Pizza Jingle featuring the Four Tops.
							LaShawn was the Choir Director for the McDonald’s 365 Black Awards
							directing the Southern University Gospel Choir with Erica Campbell
							and the Louisiana Leadership Institute Drum-line performance with
							American Idol winner, singer, and actress Jordan Sparks. In March
							2015, LaShawn worked with Grammy Award winning singer Patti
							Austin, Greg Phillinganes, and Sanchez Harley as the Assistant
							Choir Director for the Charles H. Wright Museum 50th Anniversary
							Celebration “Oh Freedom.” In 2015, LaShawn also released his debut
							album, “Love, Peace, and Praise.” He is married to Cherida D. Gary
							and together they have two children Nigel and Niyah.
						</Typography>
					</div>
				</Stack>
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					display: { xs: "none", md: "flex" },
					justifyContent: "center",
					alignItems: "center",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<Stack
					sx={{
						display: "flex",
					}}
					className="small-screen-mini-bios"
				>
					<Card
						sx={{ display: "flex", background: "none" }}
						className="sub-card"
					>
						<CardMedia
							component="img"
							className="card-img"
							sx={{
								width: "504px",
								height: "378px",
								justifyContent: "center",
								alignItems: "center",
							}}
							image={daasChoir}
							alt="image of LaShawn Gary with The Detroit Academy of Arts & Sciences Choir"
						/>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent className="mini-card-content">
								<Typography
									className="mini-bio-card"
									sx={{
										fontSize: "1.5em",
									}}
								>
									The decade ended as the Emmy Award winning producer and fellow
									producing partners, Bill Bowen and Mike Ellison released
									“Detroit: One Nation Under A Groove”, a YouTube video
									featuring the Detroit Academy of Arts and Sciences Choir which
									received over a million views in the first month. He was also
									awarded two Detroit Music Awards for “Outstanding Music Video”
									and “Outstanding Urban Recording”.
								</Typography>
							</CardContent>
						</Box>
					</Card>
					<Card sx={{ display: "flex" }} className="sub-card">
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent className="mini-card-content">
								<Typography
									className="mini-bio-card"
									sx={{
										fontSize: "1.5em",
									}}
								>
									LaShawn is a music educator and currently teaches music with
									Southfield Public Schools. He is currently the President/CEO
									of the Michigan Fine Arts Institute, formerly known as the
									Beverly Thomas Fine Arts Institute.
								</Typography>
							</CardContent>
						</Box>
						<CardMedia
							component="img"
							className="card-img"
							image={southfieldat}
							alt="southfield a&t band"
							sx={{ width: "unset" }}
						/>
					</Card>
					<Card sx={{ display: "flex" }} className="sub-card">
						<CardMedia
							component="img"
							className="card-img"
							image={oppLine}
							sx={{ width: "480px", height: "369px" }}
							alt="image of LaShawn Gary with fraternal brothers of Omega Psi Phi"
						/>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent className="mini-card-content">
								<Typography
									className="mini-bio-card"
									sx={{
										fontSize: "1.5em",
									}}
								>
									He is a member of Omega Psi Phi Fraternity Incorporated, Phi
									Mu Alpha Professional Music Fraternity, The American
									Federation of Musicians (AFM) and the American Society of
									Composers, Authors and Publishers (ASCAP).
								</Typography>
							</CardContent>
						</Box>
					</Card>
				</Stack>
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					display: { xs: "flex", md: "none" },
				}}
			>
				<Stack
					sx={{
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
						display: "flex",
					}}
				>
					<Card
						sx={{ display: "flex", background: "black" }}
						className="sub-card"
					>
						<CardMedia
							component="img"
							className="card-img"
							style={{
								width: "120px",
								height: "93px",
							}}
							image={daasChoir}
							alt="image of LaShawn Gary with The Detroit Academy of Arts & Sciences Choir"
						/>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent className="mini-card-content">
								<Typography
									className="mini-bio-card"
									sx={{
										fontSize: "0.5em",
									}}
								>
									The decade ended as the Emmy Award winning producer and fellow
									producing partners, Bill Bowen and Mike Ellison released
									“Detroit: One Nation Under A Groove”, a YouTube video
									featuring the Detroit Academy of Arts and Sciences Choir which
									received over a million views in the first month. He was also
									awarded two Detroit Music Awards for “Outstanding Music Video”
									and “Outstanding Urban Recording”.
								</Typography>
							</CardContent>
						</Box>
					</Card>
					<Card
						sx={{ display: "flex", background: "black" }}
						className="sub-card"
					>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent className="mini-card-content">
								<Typography
									className="mini-bio-card"
									sx={{
										fontSize: "0.5em",
									}}
								>
									LaShawn is a music educator and currently teaches music with
									Southfield Public Schools.
								</Typography>
							</CardContent>
						</Box>
						<CardMedia
							component="img"
							className="card-img"
							image={southfieldat}
							alt="southfield a&t band"
							style={{ width: "240px", height: "185px" }}
						/>
					</Card>
					<Card
						sx={{ display: "flex", background: "black" }}
						className="sub-card"
					>
						<a href="https://www.michiganfai.com/contact" target="_blank">
							<CardMedia
								component="img"
								className="card-img"
								width={140}
								height={122}
								image={mfai}
								alt="michigan fine arts institute"
							/>
						</a>

						<Box
							sx={{ display: "flex", flexDirection: "column", width: "70%" }}
						>
							<CardContent className="mini-card-content">
								<Typography
									className="mini-bio-card"
									sx={{
										fontSize: "0.5em",
									}}
								>
									He is currently the President/CEO of the Michigan Fine Arts
									Institute, formerly known as the Beverly Thomas Fine Arts
									Institute.
								</Typography>
							</CardContent>
						</Box>
					</Card>
					<Card
						sx={{ display: "flex", background: "black" }}
						className="sub-card"
					>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent className="mini-card-content">
								<div>
									<Typography
										className="mini-bio-card"
										sx={{
											fontSize: "0.5em",
										}}
									>
										He is a member of Omega Psi Phi Fraternity Incorporated, Phi
										Mu Alpha Professional Music Fraternity, The American
										Federation of Musicians (AFM) and the American Society of
										Composers, Authors and Publishers (ASCAP).
									</Typography>

									<Stack
										direction="row"
										spacing={0.5}
										justifyContent={"center"}
										alignItems={"center"}
									>
										<a href="http://www.sinfonia.org/" target="_blank">
											<img src={phimualpha} alt="phi mu alpha"></img>
										</a>
										<a
											href="http://www.omegapsiphifraternity.org/"
											target="_blank"
										>
											<img src={omegapsiphi} alt="omega psi phi"></img>
										</a>
										<a href="http://www.ascap.com/" target="_blank">
											<img src={ascap} alt="ascap"></img>
										</a>
										<a href="http://www.afm.org/" target="_blank">
											<img src={afm} alt="sinfonia"></img>
										</a>
									</Stack>
								</div>
							</CardContent>
						</Box>
						<CardMedia
							component="img"
							className="card-img"
							width={240}
							height={185}
							image={oppLine}
							alt="image of LaShawn Gary with fraternal brothers of Omega Psi Phi"
						/>
					</Card>
				</Stack>
			</Box>
			<Stack
				direction={"row"}
				spacing={5}
				className="image-row"
				sx={{
					overflow: "auto",
					paddingBottom: 10,
					paddingTop: 10,
				}}
			>
				<img
					src={withMom}
					alt="image of LaShawn Gary with mom"
					className="image-row-img"
				></img>
				<img
					className="image-row-img"
					src={oppConference}
					alt="image of LaShawn Gary with fraternal brothers of Omega Psi Phi at a conference"
				></img>

				<img
					className="image-row-img"
					src={ldgLive}
					alt="image of LaShawn Gary"
				></img>

				<img
					className="image-row-img"
					src={ldgUofL}
					alt="image of LaShawn Gary"
				></img>
				<img
					className="image-row-img"
					src={ldg}
					alt="image of LaShawn Gary with Evolution album"
				></img>
			</Stack>
			<Card
				sx={{
					maxWidth: 1000,
					justifyContent: "center",
					alignContent: "center",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<CardContent>
					<h1 style={{ color: "white" }}>LNC Music Inc.</h1>
					<h2 style={{ color: "white" }}>The Company Behind the Man</h2>
					<Typography style={{ color: "white" }} lineHeight={3}>
						L.N.C. Music, Inc., provides music production for film, commercial
						advertisement and the recording industry. We provide orchestral
						arrangements authentically and /or via midi through music technology
						software. It is our intention to establish a viable relationship
						with clients by providing timely, creative, and competitive music
						services that meets the expectations of the client.
					</Typography>
				</CardContent>
			</Card>
		</div>
	);
};

export default AboutMe;
