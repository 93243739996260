import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Videos = ({ youtubeVideos }) => {
	const divStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundSize: "cover",
		height: "480px",
	};

	const spanStyle = {
		padding: "20px",
		color: "#000000",
	};

	return (
		<div style={{ width: "50%", margin: "auto" }}>
			<Slide duration={5000}>
				{youtubeVideos.map((video) => (
					<div key={video.id}>
						<div
							style={{
								...divStyle,
								backgroundSize: "contain",
								backgroundImage: `url(${video.thumbnailUrl})`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
							}}
						>
							<span style={spanStyle}>
								<a href={video.url} target="_blank">
									<i
										className="fa-brands fa-youtube"
										style={{ color: "#FF0000", fontSize: "4em" }}
									></i>
								</a>
							</span>
						</div>
					</div>
				))}
			</Slide>
		</div>
	);
};

export default Videos;
