import { Button, Card, CardActions, CardContent, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import css from "./events.css";
import { DataStore } from "aws-amplify/datastore";
import { Event } from "../../models";

const Events = () => {
	const [upcomingEvents, setUpcomingEvents] = useState([]);
	const [eventsFetched, setEventsFetched] = useState(false);
	const dateOptions = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
		timeZone: "UTC",
	};
	useEffect(() => {
		(async () => {
			const events = await DataStore.query(Event);
			const filteredEvents = events.filter(
				(event) => Date.parse(event.date) > Date.now()
			);
			filteredEvents.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
			setUpcomingEvents(filteredEvents);
			setEventsFetched(true);
		})();
	}, []);

	return (
		eventsFetched && (
			<div className="events">
				<h1 className="events-header">Upcoming Events</h1>
				<Stack
					spacing={3}
					direction={"column"}
					alignItems={"center"}
					sx={{
						alignContent: "center",
						justifyContent: "center",
					}}
				>
					{upcomingEvents.map((event) => (
						<Card
							key={event.title}
							sx={{
								maxWidth: 375,
								minWidth: 375,
								color: "white",
							}}
						>
							<CardContent>
								<h2>{event.title}</h2>
								<h4>
									{new Date(event.date).toLocaleDateString(
										undefined,
										dateOptions
									)}{" "}
									{" " + event.startTime + " - "} {event.endTime}
								</h4>
								<h4>{event.location}</h4>
							</CardContent>
							<CardActions sx={{ justifyContent: "center" }}>
								<a
									href={
										event.ticketsLink ? event.ticketsLink : event.detailsLink
									}
									target="_blank"
									className="event-details-link"
								>
									<Button variant="contained">
										{event.ticketsLink ? "Get Tickets" : "Details"}
									</Button>
								</a>
							</CardActions>
						</Card>
					))}
				</Stack>
			</div>
		)
	);
};

export default Events;
