import React, { useEffect, useState } from "react";
import "./scores.css";
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Grid,
	IconButton,
	Slider,
	Stack,
	Typography,
	styled,
} from "@mui/material";
import beautifulGirl from "../../assets/images/beautiful-girl.png";
import fourAndTwentyHours from "../../assets/images/four-and-twenty-hours.png";
import niteAndDay from "../../assets/images/nite-and-day.png";
import roundMidnight from "../../assets/images/round-midnight.png";
import stayingInLove from "../../assets/images/staying-in-love.png";
import theyCantTakeThatAway from "../../assets/images/they-cant-take-that-away.png";
import ldg from "../../assets/images/ldg-grey-suit.png";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import { useTheme } from "@emotion/react";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";

const Widget = styled("div")(({ theme }) => ({
	padding: 16,
	borderRadius: 16,
	width: 343,
	maxWidth: "100%",
	margin: "auto",
	position: "relative",
	zIndex: 1,
	backgroundColor: "rgba(218,218,218, 0.1)",
	backdropFilter: "blur(40px)",
}));

const TinyText = styled(Typography)({
	fontSize: "0.75rem",
	opacity: 0.38,
	fontWeight: 500,
	letterSpacing: 0.2,
	color: "white",
});

const Scores = () => {
	const theme = useTheme();
	const [audioPlayState, setPlayState] = useState({
		audio: new Audio(
			"https://ldg-productions.s3.us-east-2.amazonaws.com/LaShawn+D.+Gary+-+Demo+_+Film+Reel+mix.mp3"
		),
		isPlaying: false,
	});
	const [position, setPosition] = React.useState(0);
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	const [intervalId, setIntervalId] = useState();
	const duration = 400;

	const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
	function handlePlayPositionChange(value) {
		audioPlayState.audio.currentTime = value;
		setPosition(value);
	}

	useEffect(() => {
		audioPlayState.audio.preload = "auto";
	}, []);
	function handleRestart() {
		setPosition(0);
		audioPlayState.audio.currentTime = 0;
	}

	function formatDuration(value) {
		const minute = Math.floor(value / 60);
		const secondLeft = Math.round(value - minute * 60);

		return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
	}

	function playPause() {
		let isPlaying = audioPlayState.isPlaying;

		if (isPlaying) {
			audioPlayState.audio.pause();
			window.clearInterval(intervalId);
		} else {
			if (position === 400) {
				handleRestart();
			}
			audioPlayState.audio.play();

			let interval = window.setInterval(handleActivePlaying, duration);
			setIntervalId(interval);
		}
		setPlayState({ ...audioPlayState, isPlaying: !isPlaying });
	}

	function handleActivePlaying() {
		setPosition(audioPlayState.audio.currentTime);
	}
	const scores = [
		beautifulGirl,
		fourAndTwentyHours,
		niteAndDay,
		roundMidnight,
		stayingInLove,
		theyCantTakeThatAway,
	];

	return (
		<div
			style={{
				justifyContent: "center",
				alignContent: "center",
				marginRight: "auto",
				marginLeft: "auto",
			}}
			className="main-container"
		>
			<h1 className="scores-header">Scores</h1>
			<div style={{ paddingBottom: 20 }}>
				<Card
					sx={{
						background: "none",
						justifyContent: "center",
						alignContent: "center",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				>
					<CardMedia
						component="img"
						sx={{
							width: "720px",
							height: "480px",
							marginLeft: "auto",
							marginRight: "auto",
							maxWidth: "100%",
						}}
						image={ldg}
						className="img-header"
						alt="image of LaShawn Gary"
					></CardMedia>
					<CardContent>
						<Typography style={{ color: "white" }} lineHeight={3}>
							LaShawn Gary is a copyist for World Renowned Arranger Paul Riser.
							“I consider it a honor and a privilege to work with Mr. Riser.” If
							there is a need for authentic orchestration or via midi, LaShawn
							Gary can accommodate your musical needs.
						</Typography>
					</CardContent>
				</Card>
			</div>
			<Widget>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Box sx={{ ml: 1.5, minWidth: 0 }}>
						<Typography noWrap color="white">
							<b>Film Demo Reel</b>
						</Typography>
					</Box>
				</Box>
				<Slider
					aria-label="time-indicator"
					size="small"
					value={position}
					min={0}
					step={1}
					max={duration}
					onChange={(_, value) => handlePlayPositionChange(value)}
					sx={{
						color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
						height: 4,
						"& .MuiSlider-thumb": {
							width: 8,
							height: 8,
							transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
							"&::before": {
								boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
							},
							"&:hover, &.Mui-focusVisible": {
								boxShadow: `0px 0px 0px 8px ${
									theme.palette.mode === "dark"
										? "rgb(255 255 255 / 16%)"
										: "rgb(0 0 0 / 16%)"
								}`,
							},
							"&.Mui-active": {
								width: 20,
								height: 20,
							},
						},
						"& .MuiSlider-rail": {
							opacity: 0.28,
						},
					}}
				/>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						mt: -2,
					}}
				>
					<TinyText>{formatDuration(position)}</TinyText>
					<TinyText>
						-
						{formatDuration(duration - position).startsWith("-")
							? "0:00"
							: formatDuration(duration - position)}
					</TinyText>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						mt: -1,
					}}
				>
					<IconButton aria-label="restart" onClick={() => handleRestart()}>
						<FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
					</IconButton>
					<IconButton
						aria-label={audioPlayState.isPlaying ? "play" : "pause"}
						onClick={() => playPause()}
					>
						{!audioPlayState.isPlaying || audioPlayState.audio.ended ? (
							<PlayArrowRounded
								sx={{ fontSize: "3rem" }}
								htmlColor={mainIconColor}
							/>
						) : (
							<PauseRounded
								sx={{ fontSize: "3rem" }}
								htmlColor={mainIconColor}
							/>
						)}
					</IconButton>
					<IconButton aria-label="next song">
						<FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
					</IconButton>
				</Box>
			</Widget>
			<div>
				<iframe
					src="https://www.youtube.com/embed/TDPSwQD9JZI?si=R-pTBw7Iix7ejk1e"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen
					className="scores-yt-iframe"
				></iframe>
			</div>
			{isTabletOrMobile ? (
				<Stack direction={"column"} sx={{ paddingBottom: 10 }}>
					<Box sx={{ ml: 1.5 }}>
						<ReactPlayer
							url="https://ldg-productions.s3.us-east-2.amazonaws.com/Xfinity+-+LDG.mp4"
							controls={true}
							width={"100%"}
							playsinline={true}
						/>
						<Typography fontWeight={500} color={"white"}>
							Xfinity - Unplug
						</Typography>
					</Box>
					<Box sx={{ ml: 1.5 }}>
						<ReactPlayer
							url="https://ldg-productions.s3.us-east-2.amazonaws.com/Toyota.mp4"
							controls={true}
							width={"100%"}
							playsinline={true}
						/>
						<Typography fontWeight={500} color={"white"}>
							Toyota Commercial
						</Typography>
					</Box>
					<Box sx={{ ml: 1.5 }}>
						<ReactPlayer
							url="https://ldg-productions.s3.us-east-2.amazonaws.com/Bless+the+Kindling+World+with+Introduction-1080p.mp4"
							controls={true}
							width={"100%"}
							playsinline={true}
						/>
						<Typography fontWeight={500} color={"white"}>
							Bless the Kindling World
						</Typography>
					</Box>
					<Box sx={{ ml: 1.5 }}>
						<ReactPlayer
							url="https://ldg-productions.s3.us-east-2.amazonaws.com/Letter+to+My+Younger+Self-1080p.mp4"
							controls={true}
							width={"100%"}
							playsinline={true}
						/>
						<Typography fontWeight={500} color={"white"}>
							Letter to My Younger Self
						</Typography>
					</Box>
					<Box sx={{ ml: 1.5 }}>
						<ReactPlayer
							url="https://ldg-productions.s3.us-east-2.amazonaws.com/BETTER_F1.mp4"
							controls={true}
							width={"100%"}
							playsinline={true}
						/>
						<Typography fontWeight={500} color={"white"}>
							Better
						</Typography>
					</Box>
				</Stack>
			) : (
				<Stack
					direction={"column"}
					sx={{ paddingBottom: 10, paddingTop: 5 }}
					spacing={8}
				>
					<Stack direction={"row"} spacing={4}>
						<Box sx={{ ml: 1.5 }}>
							<ReactPlayer
								url="https://ldg-productions.s3.us-east-2.amazonaws.com/Xfinity+-+LDG.mp4"
								controls={true}
								width="560px"
								height="315px"
							/>
							<Typography fontWeight={500} color={"white"}>
								Xfinity - Unplug
							</Typography>
						</Box>
						<Box sx={{ ml: 1.5 }}>
							<ReactPlayer
								url="https://ldg-productions.s3.us-east-2.amazonaws.com/Toyota.mp4"
								controls={true}
								width="560px"
								height="315px"
								playsinline={true}
							/>
							<Typography fontWeight={500} color={"white"}>
								Toyota Commercial
							</Typography>
						</Box>
					</Stack>
					<Stack direction={"row"} spacing={4}>
						<Box sx={{ ml: 1.5 }}>
							<ReactPlayer
								url="https://ldg-productions.s3.us-east-2.amazonaws.com/Bless+the+Kindling+World+with+Introduction-1080p.mp4"
								controls={true}
								width="560px"
								height="315px"
							/>
							<Typography fontWeight={500} color={"white"}>
								Bless the Kindling World
							</Typography>
						</Box>
						<Box sx={{ ml: 1.5 }}>
							<ReactPlayer
								url="https://ldg-productions.s3.us-east-2.amazonaws.com/Letter+to+My+Younger+Self-1080p.mp4"
								controls={true}
								width="560px"
								height="315px"
							/>
							<Typography fontWeight={500} color={"white"}>
								Letter to My Younger Self
							</Typography>
						</Box>
					</Stack>
					<Box sx={{ alignSelf: "center", alignItems: "center" }}>
						<ReactPlayer
							url="https://ldg-productions.s3.us-east-2.amazonaws.com/BETTER_F1.mp4"
							controls={true}
							width="560px"
							height="315px"
						/>
						<Typography fontWeight={500} color={"white"}>
							Better
						</Typography>
					</Box>
				</Stack>
			)}

			<Stack direction={"row"} spacing={4}></Stack>
			<Box
				sx={{
					flexGrow: 1,
					display: { xs: "none", md: "flex" },
				}}
			>
				<Grid container spacing={2} columns={16}>
					{scores.map((scoreImage, index) => (
						<Grid item xs={8} key={index}>
							<img
								key={index}
								src={scoreImage}
								alt="image of score"
								className="score-image-grid"
							></img>
						</Grid>
					))}
				</Grid>
			</Box>

			<Box
				sx={{
					flexGrow: 1,
					display: { xs: "flex", md: "none" },
				}}
			>
				<Stack
					direction={"column"}
					spacing={2}
					justifyContent={"center"}
					alignItems={"center"}
					sx={{
						marginRight: "auto",
						marginLeft: "auto",
					}}
				>
					{scores.map((scoreImage, index) => (
						<img
							src={scoreImage}
							alt="image of score"
							className="score-image-grid"
							key={index}
						></img>
					))}
				</Stack>
			</Box>
		</div>
	);
};

export default Scores;
