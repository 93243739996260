import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import evolutionImage from "../../assets/images/evolution.jpg";
import foryourlove from "../../assets/images/for-your-love.jpg";
import helpMyUnbelief from "../../assets/images/help-my-unbelief.jpg";
import inception from "../../assets/images/inception.jpg";
import lineBrothers from "../../assets/images/line-brothers.jpg";
import lovePeacePraise from "../../assets/images/love-peace-praise.jpg";
import nowIsTheTime from "../../assets/images/now-is-the-time.jpg";
import onTheYard from "../../assets/images/ontheyard.jpg";
import css from "./music-page.css";
import spotifyIcon from "../../assets/icons/spotify.png";
import tidalLogo from "../../assets/icons/tidal.png";
import amazonMusicLogo from "../../assets/icons/amazon-music.png";
import pandora from "../../assets/icons/pandora.png";
import youtubeMusic from "../../assets/icons/youtube-music.png";

const MusicPage = () => {
	return (
		<div>
			<Stack direction={"column"} spacing={4}>
				<Box>
					<h1 className="music-header">New Album Release - Evolution</h1>
					<a href="https://songwhip.com/lashawndgary/evolution" target="_blank">
						<img src={evolutionImage} width={400} height={400}></img>
					</a>
				</Box>
				{/**<Divider>Upcoming Release</Divider>
				<Box>
					<h2>**Add any upcoming releases here**</h2>
					<h3>Date Here</h3>
					<img src={mainPhoto}></img>
	</Box> **/}
				<Divider>More Music</Divider>
				<Stack direction={"row"} spacing={5} className="music-row">
					<img src={foryourlove} className="album-cover"></img>
					<img src={inception} className="album-cover"></img>
					<img src={helpMyUnbelief} className="album-cover"></img>
					<img src={nowIsTheTime} className="album-cover"></img>
					<img src={lineBrothers} className="album-cover"></img>
					<img src={lovePeacePraise} className="album-cover"></img>
					<img src={onTheYard} className="album-cover"></img>
				</Stack>
				<h1>Listen On</h1>
				<Stack direction={"row"} spacing={2} className="music-platforms-row">
					<a
						href="https://open.spotify.com/artist/0nzXlRRHKYsEIn6zAmmCvq"
						target="_blank"
					>
						<img src={spotifyIcon} width={"80px"} height={"80px"}></img>
					</a>
					<a
						href="https://music.apple.com/us/artist/lashawn-d-gary/999548244?itsct=music_box_badge&amp;itscg=30200&amp;app=music&amp;ls=1"
						target="_blank"
						style={{
							display: "inline-block",
							overflow: "hidden",
							borderRadius: "13px",
							width: "250px",
							height: "83px",
						}}
						width={"250px"}
						height={"83px"}
					>
						<img
							src="https://tools.applemediaservices.com/api/badges/listen-on-apple-music/badge/en-us?size=250x83"
							alt="Listen on Apple Music"
							style={{ borderRadius: "13px", width: "250px", height: "83px" }}
							width={"250px"}
							height={"83px"}
						></img>
					</a>
					<a href="https://tidal.com/browse/artist/6929102?u" target="_blank">
						<img src={tidalLogo} alt="tidal" className="image-background"></img>
					</a>
					<a
						href="https://music.amazon.com/artists/B00YG6VICS/lashawn-d-gary"
						target="_blank"
					>
						<img
							src={amazonMusicLogo}
							alt="amazon music"
							style={{ width: "255px", height: "82px" }}
						></img>
					</a>
					<a
						href="https://www.pandora.com/artist/lashawn-d-gary/ARr3vlnZqlhzpzg"
						target="_blank"
					>
						<img
							src={pandora}
							alt="pandora"
							style={{ width: "100px", height: "100px" }}
						></img>
					</a>
					<a
						href="https://music.youtube.com/channel/UC3DgzYs6KszIywTb8gE9sig"
						target="_blank"
					>
						<img src={youtubeMusic} alt="youtube music"></img>
					</a>
				</Stack>
			</Stack>
		</div>
	);
};

export default MusicPage;
