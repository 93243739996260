import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "../components/navbar";
import Home from "../components/home";
import AboutMe from "../components/about-me";
import VideoPage from "../components/videos-page";
import Footer from "../components/footer";
import Contact from "../components/contact";
import MusicPage from "../components/music-page";
import Events from "../components/events";
import Scores from "../components/scores";

const AppRoutes = () => {
	return (
		<BrowserRouter>
			<NavBar />
			<Routes>
				<Route path="/" element={<Home />} />,
				<Route path="/Bio" element={<AboutMe />} />,
				<Route path="/Videos" element={<VideoPage />} />,
				<Route path="/Contact" element={<Contact />} />,
				<Route path="/Music" element={<MusicPage />} />,
				<Route path="/Events" element={<Events />} />,
				<Route path="/Scores" element={<Scores />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};
export default AppRoutes;
